import isUndefined from '../is-undefined';

/**
 * Checks if the input value is not `undefined`
 *
 * @example
 *
 *      isNotUndefined(undefined)  // => false
 *      isNotUndefined(null)       // => true
 */
const isNotUndefined = (value: unknown): value is object | null =>
  // note: undefined can be overriden so to be safe we use void op
  !isUndefined(value);

export default isNotUndefined;
