/**
 * Checks if the input value is `undefined`
 *
 * @example
 *
 *      isUndefined(undefined)  // => true
 *      isUndefined(null)       // => false
 */
const isUndefined = (value: unknown): value is undefined =>
  // note: undefined can be overriden so to be safe we use void op
  Object.is(value, undefined);

export default isUndefined;
